<template>
  <v-card flat>
    <v-card-text>
      <v-container>
        <v-form
          ref="form"
        >
          <v-row>
            <v-col
              cols="6"

            >
              <v-text-field
                v-model="form.username"
                :label="$vuetify.lang.t('$vuetify.user.username')"
                :rules="rules.username"
              ></v-text-field>
            </v-col>
            <v-col
              cols="6"
            >
              <v-text-field
                v-model="form.realName"
                :label="$vuetify.lang.t('$vuetify.user.realName')"
                :rules="rules.realName"
              ></v-text-field>
            </v-col>
            <v-col
              cols="6"
            >
               <v-select
                  v-model="form.gender"
                  :label="$vuetify.lang.t('$vuetify.common.gender')"
                  :items="genderItems"
                  item-text="name"
                  item-value="value"
                ></v-select>
            </v-col>
            <v-col cols="6">
              <v-menu
                v-model="birthdayPickerMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="form.birthday"
                    :label="$vuetify.lang.t('$vuetify.user.birthday')"
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="form.birthday"
                  @input="birthdayPickerMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="6">
               <v-select
                  v-model="form.depId"
                  :label="$vuetify.lang.t('$vuetify.user.dep')"
                  :items="depItems"
                  :rules="rules.depId"
                  disabled
                  item-text="showName"
                  item-value="id"
                ></v-select>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="form.credentialNum"
                :label="$vuetify.lang.t('$vuetify.user.credentialNum')"
                :rules="rules.credentialNum"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="form.password"
                :label="$vuetify.lang.t('$vuetify.common.password')"
                :rules="rules.password"
                type="password"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="form.confirmPassword"
                :label="$vuetify.lang.t('$vuetify.common.confirmPassword')"
                :rules="rules.confirmPassword"
                type="password"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="form.phone"
                :label="$vuetify.lang.t('$vuetify.user.phone')"
                :rules="rules.phone"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="form.homePhone"
                :label="$vuetify.lang.t('$vuetify.user.homePhone')"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="form.address"
                :label="$vuetify.lang.t('$vuetify.user.address')"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="form.email"
                :label="$vuetify.lang.t('$vuetify.user.email')"
                :rules="rules.email"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
            >
              <v-textarea
                v-model="form.description"
                :label="$vuetify.lang.t('$vuetify.common.description')"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="info darken-1"
        @click="handleCancel"
      >
         {{$vuetify.lang.t('$vuetify.common.cancel')}}
      </v-btn>
      <v-btn
        color="primary darken-1"
        @click="handleSubmit"
      >
         {{$vuetify.lang.t('$vuetify.common.submit')}}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import { mapActions } from 'vuex';

  export default {
    props: {
      depInfo: {
        type: Object,
        default: () => null
      }
    },
    data() {
      return {
        birthdayPickerMenu: false,
        form: {
          id: null,
          address: null,
          birthday: null,
          credentialNum: null,
          credentialType: 'I',
          depId: null,
          description: null,
          email: null,
          gender: null,
          homePhone: null,
          password: null,
          phone: null,
          realName: null,
          username: null
        },
        depItems: [],
        genderItems: [
          {
            name: '男士',
            value: 'MALE'
          },
          {
            name: '女士',
            value: 'FEMALE'
          },
          {
            name: '未知',
            value: 'UNKNOWN'
          }
        ],
        rules: {
          username: [
            (v) =>
              !!v || this.$vuetify.lang.t('$vuetify.rule.required', [this.$vuetify.lang.t('$vuetify.user.username')])
          ],
          realName: [
            (v) =>
              !!v || this.$vuetify.lang.t('$vuetify.rule.required', [this.$vuetify.lang.t('$vuetify.user.realName')])
          ],
          credentialNum: [
            (v) =>
              !!v || this.$vuetify.lang.t('$vuetify.rule.required', [this.$vuetify.lang.t('$vuetify.user.credentialNum')])
          ],
          phone: [
            (v) =>
              !!v || this.$vuetify.lang.t('$vuetify.rule.required', [this.$vuetify.lang.t('$vuetify.user.phone')])
          ],
          password: [
            (v) =>
              !!v || this.$vuetify.lang.t('$vuetify.rule.required', [this.$vuetify.lang.t('$vuetify.common.password')])
          ],
          confirmPassword: [
            (v) =>
              !!v || this.$vuetify.lang.t('$vuetify.rule.required', [this.$vuetify.lang.t('$vuetify.common.confirmPassword')]),
            (v) =>
              v === this.form.password || this.$vuetify.lang.t('$vuetify.rule.sameAs', [this.$vuetify.lang.t('$vuetify.common.password')])
          ],
          depId: [
            (v) =>
              !!v || this.$vuetify.lang.t('$vuetify.rule.required', [this.$vuetify.lang.t('$vuetify.user.dep')])
          ],
          email: [
            (v) =>
              !!v || this.$vuetify.lang.t('$vuetify.rule.required', [this.$vuetify.lang.t('$vuetify.user.email')]),
            (v) =>
              /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(v) || this.$vuetify.lang.t('$vuetify.rule.format', [this.$vuetify.lang.t('$vuetify.user.email')])
          ]
        }
      }
    },
    computed: {
    },
    created () {
      this.initialize()
    },
    methods: {
      ...mapActions ({
        setStoreDepAdmin: 'department/setStoreDepAdmin',
        userCreate: 'user/create',
      }),
      initialize () {
        this.depItems = [this.depInfo]
        this.form.depId = this.depInfo.id
      },
      resetFrom () {
        for (let key in this.form) {
          this.form[key] = null
        }
        this.form.credentialType = 'I'
        this.$refs.form.resetValidation()

      },
      handleCancel() {
        this.$emit('cancel', true)
      },
      handleSubmit() {
        if (this.$refs.form.validate()) {
          const data = Object.assign({}, this.form)
          data.type = 'MEDICAL_INSTITUTION_USER'
          delete data.confirmPassword
          this.userCreate(data).then(( data ) => {
            this.setStoreDepAdmin({ id: this.depInfo.id,  userId: data.id}).then( () => {
              this.$emit('submit', true)
            })
          })
        }
      }
    }
  }
</script>
